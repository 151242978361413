import Vue from 'vue'
import Vuex from 'vuex'

import {
  adminChangePassword,
  updateAdmin,
  getAdmin,
  getAdminMarket,
  updateMarketSettings,
  marketLogo,
  createMarketImage,
  deleteMarketImage,
} from '@admin/services'

import {
  baseActions,
  baseGetters,
  baseMutations,
  baseState,
  adminRequestInterceptor,
  cleanStorages,
  adminResponseFulfilledInterceptor,
  adminResponseRejectedInterceptor,
  getToken,
  setToken,
} from '@base/store'

import { actionTypes, mutationTypes } from '@base/store/store-types'
import { webConfig } from '@base/configs'
import { revokeToken } from '@base/services'
import i18n from '@base/i18n'

Vue.use(Vuex)

const initialState = {
  ...baseState,
  adminMarket: null,
  adminNavItems: [],
}

const axiosInstances = [
  adminChangePassword,
  updateAdmin,
  getAdmin,
  getAdminMarket,
  updateMarketSettings,
  marketLogo,
  createMarketImage,
  deleteMarketImage,
]

for (const instance of axiosInstances) {
  instance.interceptors.request.use(adminRequestInterceptor)
  instance.interceptors.response.use(
    adminResponseFulfilledInterceptor,
    adminResponseRejectedInterceptor
  )
}


document.initialState = initialState      // Needs to be in each section stores for base auth user behavior

export default new Vuex.Store({
  state: initialState,
  getters: {
    ...baseGetters,
  },
  mutations: {
    ...baseMutations,

    // eslint-disable-next-line no-unused-vars
    [mutationTypes.LOGOUT_ADMIN](state, url) {
      state = document.initialState
      cleanStorages('AdminToken')
      document.location = url ? url : webConfig.baseURL
    },
    [mutationTypes.SET_ADMIN_MARKET](state, market) {
      state.adminMarket = market
    },
    [mutationTypes.SET_MARKET_SETTINGS](state, market) {
      state.adminMarket = market
    },
    [mutationTypes.DELETE_MARKET_LOGO](state) {
      state.adminMarket.logo = null
    },
    [mutationTypes.APPEND_MARKET_IMAGE](state, newImage) {
      state.adminMarket.marketImages.push(newImage)
    },
    [mutationTypes.DELETE_MARKET_IMAGE](state, imageId) {
      const oldImage = state.adminMarket.marketImages.findIndex(
        obj => obj.id === imageId
      )
      state.adminMarket.marketImages.splice(oldImage, 1)
    },
    [mutationTypes.SET_ADMIN_NAV_ITEMS](state) {
      state.adminNavItems = [
        {
          href: { name: 'market-settings' },
          text: i18n.t('navigation.admin.market_settings')
        },
        {
          href: { name: 'user-settings' },
          text: i18n.t('navigation.general.settings')
        },
        {
          href: '#',
          text: i18n.t('navigation.general.logout')
        }
      ]
    },
  },
  actions: {
    ...baseActions,

    [actionTypes.GET_ADMIN_MARKET]({ state, commit }) {
      return new Promise((resolve, reject) => {
        getAdminMarket({
          url: `${state.marketId}/`
        })
          .then(response => {
            commit(mutationTypes.SET_ADMIN_MARKET, response.data)
            commit(mutationTypes.SET_ADMIN_NAV_ITEMS)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_ADMIN_DATA]({ commit }) {
      return new Promise((resolve, reject) => {
        getAdmin()
          .then((response) => {
            commit(mutationTypes.SET_USER_DATA, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.CHANGE_ADMIN_DATA] ({commit}, payloads) {
      return new Promise((resolve, reject) => {
        updateAdmin({
          data: payloads
        })
          .then((response) => {
            const token = getToken('AdminToken')
            if (token) {
              token.username = response.data.username
              setToken(token)
              commit(mutationTypes.SET_USER_DATA, response.data)
            } else {
              commit(mutationTypes.LOGOUT_ADMIN)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.LOGOUT_ADMIN]({ commit }, url) {
      return new Promise((resolve, reject) => {
        const token = getToken('AdminToken')
        if (!token || !token.accessToken) {
          commit(mutationTypes.LOGOUT_ADMIN)
        }
        revokeToken({
          data: {'token': token.accessToken}
        })
          .then(response => {
            commit(mutationTypes.LOGOUT_ADMIN, url)
            resolve(response)
          })
          .catch(error => {
            commit(mutationTypes.LOGOUT_ADMIN)
            reject(error)
          })
      })
    },

    [actionTypes.UPDATE_MARKET_SETTINGS]({ state, commit, dispatch }, { payloads, method = 'put' }) {
      return new Promise((resolve, reject) => {
        updateMarketSettings({
          method: method,
          url: `${state.marketId}/`,
          data: payloads
        })
          .then(response => {
            if (payloads.marketId) {
              const token = getToken('AdminToken')
              if (token) {
                if (token.marketId) {
                  token.marketId = response.data.marketId
                  token.flexposApiValid = response.data.flexposApiValid
                  token.economicApiValid = response.data.economicApiValid
                  setToken(token)
                  commit(mutationTypes.SET_MARKET_HEADER, token.marketId ? token.marketId : '')
                } else {
                  dispatch(actionTypes.LOGOUT_ADMIN)
                  reject({data: ['No marketId in token']})
                }
              } else {
                commit(mutationTypes.LOGOUT_ADMIN)
                reject({data: ['No token']})
              }
            }
            commit(mutationTypes.SET_MARKET_SETTINGS, response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.UPDATE_MARKET_LOGO]({ state }, formDataLogoFile) {
      return new Promise((resolve, reject) => {
        marketLogo({
          url: `${state.marketId}/`,
          data: formDataLogoFile,
          method: 'put'
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.DELETE_MARKET_LOGO]({ state, commit }) {
      return new Promise((resolve, reject) => {
        marketLogo({
          url: `${state.marketId}/`,
          method: 'delete'
        })
          .then(response => {
            commit(mutationTypes.DELETE_MARKET_LOGO)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.CREATE_MARKET_IMAGE]({ commit }, imageFile) {
      return new Promise((resolve, reject) => {
        createMarketImage({
          data: imageFile
        })
          .then(response => {
            commit(mutationTypes.APPEND_MARKET_IMAGE, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.DELETE_MARKET_IMAGE]({ commit }, imageId) {
      return new Promise((resolve, reject) => {
        deleteMarketImage({
          url: `${imageId}/`,
        })
          .then(response => {
            commit(mutationTypes.DELETE_MARKET_IMAGE, imageId)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
})
