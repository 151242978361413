export const actionTypes = {

  // Base
  INIT_USER: 'INIT_USER',
  GET_ADMIN_DATA: 'GET_ADMIN_DATA',
  GET_CUSTOMER_DATA: 'GET_CUSTOMER_DATA',
  LOGOUT_CUSTOMER: 'LOGOUT_CUSTOMER',
  LOGOUT_ADMIN: 'LOGOUT_ADMIN',
  CHANGE_CUSTOMER_DATA: 'CHANGE_CUSTOMER_DATA',
  CHANGE_ADMIN_DATA: 'CHANGE_ADMIN_DATA',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SAVE_USER_DATA: 'SAVE_USER_DATA',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  DISCONNECT_INSTAGRAM_ACCOUNT: 'DISCONNECT_INSTAGRAM_ACCOUNT',
  GET_MARKET_INSTAGRAM_MEDIA: 'GET_MARKET_INSTAGRAM_MEDIA',

  // Anonymous
  LOGIN_USER: 'LOGIN_USER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  REGISTER_USER: 'REGISTER_USER',
  GET_RESET_PASSWORD_DATA: 'GET_RESET_PASSWORD_DATA',
  GET_CONFIRMATION_DATA: 'GET_CONFIRMATION_DATA',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  CONFIRM_RESET_PASSWORD: 'CONFIRM_RESET_PASSWORD',
  GET_ANON_MARKET: 'GET_ANON_MARKET',
  CREATE_MARKET: 'CREATE_MARKET',
  SEND_FEEDBACK: 'SEND_FEEDBACK',

  // Admin
  GET_ADMIN_MARKET: 'GET_ADMIN_MARKET',
  UPDATE_MARKET_SETTINGS: 'UPDATE_MARKET_SETTINGS',
  UPDATE_MARKET_LOGO: 'UPDATE_MARKET_LOGO',
  DELETE_MARKET_LOGO: 'DELETE_MARKET_LOGO',
  CREATE_MARKET_IMAGE: 'CREATE_MARKET_IMAGE',
  DELETE_MARKET_IMAGE: 'DELETE_MARKET_IMAGE',
}

export const mutationTypes = {
  // Base
  SET_LOADER: 'SET_LOADER',
  SET_MARKET_HEADER: 'SET_MARKET_HEADER',
  SET_TOKEN: 'SET_TOKEN',
  SET_AUTH: 'SET_AUTH',
  SET_USER: 'SET_USER',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
  LOGOUT_CUSTOMER: 'LOGOUT_CUSTOMER',
  LOGOUT_ADMIN: 'LOGOUT_ADMIN',
  SET_MARKET_INSTAGRAM_MEDIA: 'SET_MARKET_INSTAGRAM_MEDIA',

  // Anonymous
  SET_RESET_PASSWORD_DATA: 'SET_RESET_PASSWORD_DATA',
  SET_CONFIRMATION_DATA: 'SET_CONFIRMATION_DATA',
  SET_ANON_MARKET: 'SET_ANON_MARKET',

  // Admin
  SET_ADMIN_MARKET: 'SET_ADMIN_MARKET',
  SET_MARKET_SETTINGS: 'SET_MARKET_SETTINGS',
  DELETE_MARKET_LOGO: 'DELETE_MARKET_LOGO',
  APPEND_MARKET_IMAGE: 'APPEND_MARKET_IMAGE',
  DELETE_MARKET_IMAGE: 'DELETE_MARKET_IMAGE',
  SET_ADMIN_NAV_ITEMS: 'SET_ADMIN_NAV_ITEMS',

}
