import i18n from '@base/i18n'

export const host = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_DOMAIN : 'localhost:8001'
export const storeHost = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_STORE_DOMAIN : 'localhost:8001'

export const SERVER_TYPE = process.env.VUE_APP_SERVER_TYPE

export const wsHost = `wa://${host}/`

// Envs which we will use for our app, deployed to Google Play and App Store
const standaloneAppSchemeEnvs = ['PRODUCTION']
export const useStandaloneMobileAppScheme = standaloneAppSchemeEnvs.includes(SERVER_TYPE)

// In which envs we are using deep linking for mobile app
const mobileAppSchemeEnvs = ['DEV', 'STAGING']
export const useMobileAppScheme = mobileAppSchemeEnvs.includes(SERVER_TYPE)

const getMobileAppScheme = () => {
  if (useStandaloneMobileAppScheme)
    return 'flea-lover://'
  if (SERVER_TYPE === 'STAGING')
    return 'exp://exp.host/@arossoftware/flea-lover/--'
  if (SERVER_TYPE === 'DEV')
    return `exp://${process.env.VUE_APP_LOCAL_IP}/--`
}

export const mobileAppScheme = getMobileAppScheme()

export const storeUrl = process.env.NODE_ENV === 'production' ? `https://${storeHost}` : `http://${storeHost}`

export const webConfig = {
  baseURL: process.env.NODE_ENV === 'production' ? `https://${host}` : `http://${host}`,
}
export const userConfig = {
  baseURL: `${webConfig.baseURL}/accounts`
}

export const marketConfig = {
  baseURL: `${webConfig.baseURL}/markets`
}

export const apiConfig = {
  baseURL: `${webConfig.baseURL}/api/v1`
}

export const adminApiConfig = {
  baseURL: `${apiConfig.baseURL}/admin`
}

export const loaderOptions = {
  color: '#4b8bff',
  backgroundColor: '#000',
  opacity: 0.1
}

export const RECAPTCHA_SITE_KEY = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY

export const INSTAGRAM_APP_ID = process.env.VUE_APP_INSTAGRAM_APP_ID

const USE_RECAPTCHA_ENVS = ['PRODUCTION']
export const USE_RECAPTCHA = USE_RECAPTCHA_ENVS.includes(SERVER_TYPE)

export const TIME_TOKEN_REFRESH_MILLISECONDS = 100 * 86400 * 1000  // Token will refresh in 100 days

export const REDIRECT_COUNTDOWN_SECONDS = 4

export const ADMIN = 1

export const USER_TYPES = {
  ADMIN: ADMIN,
}

export const USER_TYPE_OPTIONS = [
  { name: i18n.t(`admin.multiselect.single_label.user_type.${ADMIN}`), type: ADMIN },
]

// Field length settings
export const MIN_NAME_FIELD_LENGTH = 4
export const MAX_NAME_FIELD_LENGTH = 20
export const MIN_USERNAME_LENGTH = 4
export const MAX_USERNAME_LENGTH = 20
export const MIN_PASSWORD_LENGTH = 8

export const MAX_SCREEN_WIDTH_MOBILE = 575

export const PHONE_DEFAULT_COUNTRY_CODE = 'DK'

export const BOOLEAN_OPTIONS = [
  { value: true, name: i18n.t('multiselect.single_label.boolean.true') },
  { value: false, name: i18n.t('multiselect.single_label.boolean.false') }
]

const MONDAY = 1
const TUESDAY = 2
const WEDNESDAY = 3
const THURSDAY = 4
const FRIDAY = 5
const SATURDAY = 6
const SUNDAY = 7

export const OPENING_HOURS = [
  {
    weekdayCode: MONDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${MONDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: TUESDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${TUESDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: WEDNESDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${WEDNESDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: THURSDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${THURSDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: FRIDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${FRIDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: SATURDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${SATURDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: SUNDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${SUNDAY}`),
    fromHour: '',
    toHour: ''
  },
]

const INFO = 1
const WARNING = 2
const ERROR = 3

export const LEVEL_CHOICES = {
  [INFO]: 'info',
  [WARNING]: 'warning',
  [ERROR]: 'danger',
}

export const DEFAULT_DATEPICKER_SHORTCUTS = [
  { key: 'thisWeek', label: i18n.t('datepicker.shortcuts.this_week'), value: 'isoWeek' },
  { key: 'lastWeek', label: i18n.t('datepicker.shortcuts.last_week'), value: '-isoWeek' },
  { key: 'last7Days', label: i18n.t('datepicker.shortcuts.last_7_days'), value: 7 },
  { key: 'last30Days', label: i18n.t('datepicker.shortcuts.last_30_days'), value: 30 },
  { key: 'thisMonth', label: i18n.t('datepicker.shortcuts.this_month'), value: 'month' },
  { key: 'lastMonth', label: i18n.t('datepicker.shortcuts.last_month'), value: '-month' },
  { key: 'thisYear', label: i18n.t('datepicker.shortcuts.this_year'), value: 'year' },
  { key: 'lastYear', label: i18n.t('datepicker.shortcuts.last_year'), value: '-year' }
]

export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'

export const ADMIN_SECTION_PATH = '/admin#/'
export const USER_SECTION_PATH = '/user#/'
export const DJANGO_ADMIN_PATH_NAME = 'admin/'

export const APP_STORE_URL = 'https://apps.apple.com/us/app/flea-lover/id1567180764'
export const PLAY_MARKET_URL = 'https://play.google.com/store/apps/details?id=com.flealover'

export const INSTAGRAM_MEDIA_TYPE_IMAGE = 'IMAGE'
export const INSTAGRAM_MEDIA_TYPE_VIDEO = 'VIDEO'
export const INSTAGRAM_MEDIA_TYPE_CAROUSEL = 'CAROUSEL_ALBUM'
