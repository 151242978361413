import axios from 'axios'
import { adminApiConfig, userConfig, webConfig } from '@base/configs'


export const adminChangePassword = axios.create({
  method: 'post',
  baseURL: `${webConfig.baseURL}/password/change/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getAdmin = axios.create({
  method: 'get',
  baseURL: `${userConfig.baseURL}/admin/`,
  headers: { 'Accept': 'application/json' },
})

export const updateAdmin = axios.create({
  method: 'patch',
  baseURL: `${userConfig.baseURL}/admin/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Market

export const getAdminMarket = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/markets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const updateMarketSettings = axios.create({
  baseURL: `${adminApiConfig.baseURL}/markets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const marketLogo = axios.create({
  baseURL: `${adminApiConfig.baseURL}/market_logo/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
  }
})

export const deleteMarketImage = axios.create({
  method: 'delete',
  baseURL: `${adminApiConfig.baseURL}/images/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const createMarketImage = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/images/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
  }
})
