<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-img
          class="cursor-pointer"
          width="150"
          height="130"
          :src="require('@base/assets/images/sitelogo.png')"
          @click="redirectToHomePage"
        />
      </div>
      <b-row v-if="marketInstagramMedia.ownerMarketName" class="justify-content-center">
        <h4 class="w-100 text-center bg-dark text-white p-2 rounded">{{ marketInstagramMedia.ownerMarketName }}</h4>
      </b-row>
      <b-row class="mt-3" v-if="marketInstagramMedia && marketInstagramMedia.children.length">
        <b-col
          class="mt-2 text-center"
          cols="12" md="12" sm="12" lg="12"
        >
          <b-card ref="media-card">
            <div v-if="marketInstagramMedia.mediaType === instagramMediaTypeCarousel">
              <b-carousel
                id="carousel-1"
                class="instagram-carousel"
                controls
                :interval="0"
                indicators
                background="black"
                style="text-shadow: 1px 1px 2px #333; height: 450px;"
              >
                <b-carousel-slide
                  v-for="(media, key) in marketInstagramMedia.children"
                  :key="key"
                >
                  <div v-if="media.subMediaType === instagramMediaTypeImage">
                    <b-img
                      style="max-width: 100%; max-height: 350px; margin-bottom: 1rem"
                      center
                      height="350"
                      :src="media.subMediaUrl"
                      alt="flealover" />
                  </div>
                  <div v-else-if="media.subMediaType === instagramMediaTypeVideo">
                    <video-player
                      class="video-player-box"
                      :options="subPlayerOptions(media)"
                      ref="videoPlayer"
                      :playsinline="true"
                    ></video-player>
                  </div>
                </b-carousel-slide>
              </b-carousel>
            </div>
            <span>{{ marketInstagramMedia.caption }}</span>
          </b-card>
        </b-col>
        <b-col
          v-if="anonMarket"
          cols="12" md="12" sm="12" lg="12"
          class="mb-5"
        >
          <div style="max-height: 400px">
            <market-contact-info-card
              :instagram-media-id="marketInstagramMedia.mediaId"
              :market="anonMarket"
              :market-feedback="marketFeedback"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-else-if="marketInstagramMedia && !marketInstagramMedia.children.length">
        <b-col
          v-if="marketInstagramMedia && marketInstagramMedia.mediaUrl"
          class="mt-2 text-center"
          cols="12" md="12" sm="12" lg="6"
        >
          <b-card ref="media-card">
            <div v-if="marketInstagramMedia.mediaType === instagramMediaTypeImage">
              <b-img
                style="max-width: 100%; max-height: 413px; margin-bottom: 1rem"
                center
                height="413"
                :src="marketInstagramMedia.mediaUrl"
                alt="flealover" />
            </div>
            <div v-else-if="marketInstagramMedia.mediaType === instagramMediaTypeVideo">
              <video-player
                class="video-player-box"
                :options="playerOptions"
                ref="videoPlayer"
                :playsinline="true"
              ></video-player>
            </div>
            <span>{{ marketInstagramMedia.caption }}</span>
          </b-card>
        </b-col>
        <b-col
          v-if="anonMarket"
          cols="12" md="12" sm="12" lg="6"
          class="mb-5"
        >
          <div style="max-width: 100%; max-height: 400px">
            <market-contact-info-card
              :instagram-media-id="marketInstagramMedia.mediaId"
              :market="anonMarket"
              :market-feedback="marketFeedback"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-5 justify-content-center">
        <a
          :href="appStoreUrl"
          target="_blank"
        >
          <b-img
            width="200"
            height="100"
            :src="require(`@base/assets/images/badges/download-on-the-app-store-${$_languageCode}.svg`)"
          />
        </a>
        <a
          :href="playMarketUrl"
          target="_blank"
        >
          <b-img
            width="230"
            height="100"
            :src="require(`@base/assets/images/badges/google-play-badge-${$_languageCode}.png`)"
          />
        </a>
      </b-row>
    </b-container>
  </div>
</template>

<script>

  import { mapState } from 'vuex'
  import { videoPlayer } from 'vue-video-player'
  import MarketContactInfoCard from '@base/components/MarketContactInfoCard.vue'
  import {
    APP_STORE_URL, PLAY_MARKET_URL, webConfig,
    INSTAGRAM_MEDIA_TYPE_IMAGE, INSTAGRAM_MEDIA_TYPE_VIDEO,
    INSTAGRAM_MEDIA_TYPE_CAROUSEL
  } from '@base/configs'
  import { marketFeedback } from '@base/services'
  import { anonRequestInterceptor } from '@base/store'

  marketFeedback.interceptors.request.use(anonRequestInterceptor)

  export default {
    name: 'InstagramMediaView',

    components: {
      MarketContactInfoCard,
      videoPlayer,
    },

    data () {
      return {
        appStoreUrl: APP_STORE_URL,
        playMarketUrl: PLAY_MARKET_URL,
        loading: false,
        marketFeedback: marketFeedback,
        instagramMediaTypeImage: INSTAGRAM_MEDIA_TYPE_IMAGE,
        instagramMediaTypeVideo: INSTAGRAM_MEDIA_TYPE_VIDEO,
        instagramMediaTypeCarousel: INSTAGRAM_MEDIA_TYPE_CAROUSEL,
        mediaCard: null,
        videoWidth: '450px',
        videoHeight: '400px',
      }
    },

    created () {
      window.addEventListener('resize', this.handleResize)
      this.getAnonMarket()
    },

    computed: {
      ...mapState(['marketInstagramMedia', 'anonMarket']),
      player () {
        return this.$refs.videoPlayer.player
      },
      playerOptions () {
        return {
          muted: true,
          language: this.$_languageCode,
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: this.videoWidth,
          height: this.videoHeight,
          sources: [{
            type: 'video/mp4',
            src: this.marketInstagramMedia.mediaUrl,
          }],
          poster: this.marketInstagramMedia.thumbnailUrl,
        }
      }
    },

    methods: {
      getAnonMarket () {
        if (this.loading)
          return
        this.loading = true
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.GET_ANON_MARKET, this.marketInstagramMedia.ownerMarketId)
          .then(() => {
            this.mediaCard = this.$refs['media-card']
            this.setVideoDimensions()
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loader.hide()
            this.loading = false
          })
      },

      redirectToHomePage () {
        window.location.replace(webConfig.baseURL)
      },

      setVideoDimensions () {
        this.videoWidth = this.mediaCard ? `${this.mediaCard.offsetWidth - 45}px` : '450px'
        this.videoHeight = this.mediaCard ? `${this.mediaCard.offsetHeight - 42}px` : '400px'
      },

      handleResize () {
        this.setVideoDimensions()
      },

      subPlayerOptions (media) {
        return {
          muted: true,
          language: this.$_languageCode,
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: this.videoWidth,
          height: this.videoHeight,
          sources: [{
            type: 'video/mp4',
            src: media.subMediaUrl,
          }],
          poster: media.subThumbnailUrl,
        }
      }

    }
  }

</script>

<style>

</style>
