<template>
  <div class="languages-menu">
    <ul>
      <li @click="changeLocale('da')"
          class="cursor-pointer"
          :class="{selected_lang: selectedLanguage('da')}">
        <div class="d-inline" style="font-size:25px">
          <flag iso="dk" />
        </div>
      </li>
      <li @click="changeLocale('en')"
          class="cursor-pointer"
          :class="{selected_lang: selectedLanguage('en')}">
        <div class="d-inline" style="font-size:25px">
          <flag iso="gb" />
        </div>
      </li>
      <!--      <li @click="changeLocale('de')"-->
      <!--          class="cursor-pointer"-->
      <!--          :class="{selected_lang: selectedLanguage('de')}">-->
      <!--        <flag iso="de" />-->
      <!--      </li>-->
    </ul>
  </div>
</template>

<script>

  export default {
    name: 'LanguagesMenu',

    data () {
      return  {
      }
    },

    methods: {
      selectedLanguage(language) {
        return language === this.$i18n.locale
      },

      changeLocale(language) {
        this.$store.dispatch(this.$_actionTypes.CHANGE_LANGUAGE, {
          language: language,
        })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
      },
    }
  }
</script>

<style scoped>

</style>
