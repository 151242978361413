import axios from 'axios'
import {storeUrl, marketConfig, userConfig, webConfig} from '@base/configs'

export const refreshToken = axios.create({
  method: 'post',
  baseURL: `${userConfig.baseURL}/token/refresh/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const revokeToken = axios.create({
  method: 'post',
  baseURL: `${userConfig.baseURL}/token/revoke/`,
  headers: { 'Accept': 'application/json' }
})

export const getBaseUserList = axios.create({
  method: 'get',
  baseURL: `${userConfig.baseURL}/users/`,
  headers: { 'Accept': 'application/json' },
})

// Language

export const changeLanguage = axios.create({
  method: 'post',
  baseURL: `${webConfig.baseURL}/i18n/setlang/`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  },
  withCredentials: true,
})

export const storeChangeLanguage = axios.create({
  method: 'post',
  baseURL: `${storeUrl}/i18n/setlang/`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  },
  withCredentials: true,
})


export const marketFeedback = axios.create({
  method: 'post',
  baseURL: `${marketConfig.baseURL}/market_feedback/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const instagramAccountAuth = axios.create({
  baseURL: `${userConfig.baseURL}/auth/instagram/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})


export const getMarketMedia = axios.create({
  method: 'get',
  baseURL: `${marketConfig.baseURL}/post/flealover/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})
