<template>
  <div id="app">
    <nav-bar />
    <div class="not-found-section">
      <div class="not-found-content">
        <h1>{{ $t('page_not_found.header') }}</h1>
        <span>{{ $t('page_not_found.text') }}</span>
        <!--Remove redirect to base page if we are on
        store app domain and not in local development-->
        <p class="mt-2" v-if="window.location.host !== storeHost || serverType === 'DEV'">
          <b-button @click="$router.push({ name: 'base' })">
            {{ $t('page_not_found.text_button') }}
          </b-button>
        </p>
      </div>
      <b-img fluid :src="require('@base/assets/images/svg/page-not-found.svg')"></b-img>
    </div>
    <router-view />
    <custom-footer />
  </div>
</template>

<script>

  import NavBar from '@base/components/NavBar.vue'
  import CustomFooter from '@base/components/CustomFooter.vue'
  import {SERVER_TYPE, storeHost} from '@base/configs'

  export default {
    name: 'NotFound',

    components: {
      NavBar,
      CustomFooter
    },

    data () {
      return {
        window: window,
        serverType: SERVER_TYPE,
        storeHost: storeHost,
      }
    },

    created () {
      this.$store.commit(this.$_mutationTypes.SET_LOADER, false)
    }
  }
</script>
